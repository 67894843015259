
import { defineComponent, ref, onMounted, computed,watchEffect } from "vue";
import { useStore } from "vuex";
import { Mutations } from "@/store/enums/StoreEnums";
import PolicyService from "@/core/services/car/PolicyService";
// import PolicyCancelRefundAction from "@/components/dropdown/car/PolicyCancelRefundAction.vue";
import UserService from "@/core/services/UserService";
import Multiselect from "@vueform/multiselect";
import LocalStorageServices from "@/core/services/LocalStorageServices";
import EditCancelApprovalModal from "@/components/modals/forms/EditCancelApprovalModal.vue";
import { ElNotification } from "element-plus";
import { onBeforeRouteLeave } from "vue-router";
// import EditPolicyRefundModal from "@/components/modals/forms/EditPolicyRefundModal.vue";
import MasterService from "@/core/services/car/MasterService";
import PolicyDocumentModal from "@/components/modals/forms/PolicyDocumentModal.vue";
import ViewTempDocumentModal from "@/components/modals/forms/TempdocumentModal.vue";
import { notif } from "@/store/stateless/store";
import cancel from "@/components/partials/policy/tables/cancel.vue";
import router from "@/router";
import ViewAllDocumentsModal from "@/components/modals/forms/ViewAllDocumentsModal.vue";
import mainSocket from "./../../../main-socket";

interface PolicyBodyParam {
  per_page: string;
  status: any | null;
  keyword: string;
  start_date: string | null;
  end_date: string | null;
  agent: string;
  policy_sales_agent: any;
  underwriter: any;
  payment_type: any;
  refund_status: any | null;
  cancellation_status: any | null;
  is_cancellation_selected: any | null;
  index: number;
  type: string;
}
interface CancelPolicyBodyParam {
  policy_id: any | null;
  cancellation_payable_amount: any | null;
  cancellation_debit_amount: any | null;
  cancellation_credit_amount: any | null;
  cancellation_status: any | null;
  pay_to: any | null;
  quote_ref_number: any | null;
  cancellation_type: any | null;
}
interface RefunPolicyBodyParam {
  policy_id: any | null;
  refund_payable_amount: any | null;
  refund_debit_amount: any | null;
  refund_credit_amount: any | null;
  refund_status: any | null;
  pay_to: any | null;
  policy_status: any | null;
  refund_account_notes: any | null;
  cancellation_status: any | null;
}
export default defineComponent({
  name: "list-policy",
  components: {
    // PolicyCancelRefundAction,
    EditCancelApprovalModal,
    ViewAllDocumentsModal,
    PolicyDocumentModal,
    ViewTempDocumentModal,
    cancel,
  },
  props: {
    status: Number,
    isApproveViewCancellation: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      shortcuts: [
        {
          text: "Last week",
          value: (() => {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            return [start, end];
          })()
        },
        {
          text: "Last month",
          value: (() => {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            return [start, end];
          })()
        },
        {
          text: "Last 3 months",
          value: (() => {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            return [start, end];
          })()
        }
      ],
      value1: "",
      // value2: "",
      PolicyStatus: ["-", "Pending", "Completed", "Failed"]
    };
  },
  setup(props) {
    const store = useStore();
    let exportUrl = ref<string>("");
    let policyDetails = ref<any>();
    let tempDocs = ref<any>("");

    let cancellationDocs = ref<any>("");
    let policyDocuments = ref<any>({});
    let categories = ref<any>("");
    let cancellationStatus = ref<any>("6");
    let isViewCancellation = ref<any>(false);
    const value2 = ref([]);

    let bodyParameters = ref<PolicyBodyParam>({
      status: props.status,
      per_page: "50",
      keyword: "",
      start_date: "",
      end_date: "",
      agent: "all",
      policy_sales_agent: "",
      underwriter: "",
      payment_type: "",
      refund_status: "",
      cancellation_status: "",
      is_cancellation_selected: 1,
      index: 1,
      type: ""
    });
    let cancelPolicyBodyParam = ref<CancelPolicyBodyParam>({
      policy_id: null,
      cancellation_payable_amount: null,
      cancellation_debit_amount: null,
      cancellation_credit_amount: null,
      cancellation_status: "6",
      pay_to: -1,
      quote_ref_number: null,
      cancellation_type: null
    });
    let refundPolicyBodyParam = ref<RefunPolicyBodyParam>({
      policy_id: null,
      refund_payable_amount: 0,
      refund_debit_amount: 0,
      refund_credit_amount: 0,
      refund_status: null,
      pay_to: 4,
      policy_status: 1,
      refund_account_notes: "",
      cancellation_status: ""
    });
    let agentList = computed(() => {
      return store.getters.getDomSalesAgent;
    });
    // let agentList = ref({
    //   data: [],
    //   options: store.getters.getDomSalesAgent,
    //   placeholder: "Please Select Policy Sales Agent",
    //   searchable: true,
    //   createTag: true
    // });
    // let underWriterList = ref({
    //   data: [],
    //   options: store.getters.getDomSalesAgent,
    //   placeholder: "Please Select Underwritter",
    //   searchable: true,
    //   createTag: true
    // });
    let underWriterList = computed(() => {
      return store.getters.getUnderWriters;
    });

    let refundStatusOptions = ref([
      
      { value: "1", label: "For Refund Approval" },
      { value: "3", label: "Approved" },
      { value: "2", label: "Completed" },
      { value: "4", label: "Declined" }
    ])
    // let refundStatus = ref({
    //   data: [],
    //   value: "1",
    //   options: [
    //     { value: "1", label: "For Refund Approval" },
    //     { value: "3", label: "Approved" },
    //     { value: "2", label: "Completed" },
    //     { value: "4", label: "Declined" }
    //   ],
    //   placeholder: "Please Select Refund Status"
    // });
    let cancelStatusOptions = ref([
      
      { value: "6", label: "For Cancellation Approval" },
      { value: "8", label: "Approved" },
      { value: "7", label: "Completed" },
      { value: "9", label: "Declined" }
    ]);
    // let cancelStatus = ref({
    //   data: [],
    //   value: "6",
    //   options: [
    //     { value: "6", label: "For Cancellation Approval" },
    //     { value: "8", label: "Approved" },
    //     { value: "7", label: "Completed" },
    //     { value: "9", label: "Declined" }
    //   ],
    //   placeholder: "Please Select Cancellation Status"
    // });
    let userStr: any;
    let userObj = ref<any>({});
    let childPolicyId = ref<any>({});

    const getUnderwritterAgentList = () => {
      Promise.all([UserService.getDomSalesAgents({
        type: 'Car',
        isArchived: true,
        isActive: false
      })]).then((data) => {
        agentList = computed(() => {
          return store.getters.getDomSalesAgent;
        });
        // agentList.value = {
        //   data: [],
        //   options: store.getters.getDomSalesAgent,
        //   placeholder: "Please Select Policy Sales Agent",
        //   searchable: true,
        //   createTag: true
        // };
      });
      Promise.all([UserService.getUnderWriters()]).then((data) => {
        underWriterList = computed(() => {
          return store.getters.getUnderWriters;
        });
        // underWriterList.value = {
        //   data: [],
        //   options: store.getters.getUnderWriters,
        //   placeholder: "Please Select Underwritter",
        //   searchable: true,
        //   createTag: true
        // };
      });
    };
    getUnderwritterAgentList();
    let getActiveTab = computed(() => {
      return store.getters.getQuickLinkTab;
    });
    onMounted(async() => {
      // API Action to get customers list
      bodyParameters.value.keyword = "";

      // if(router.currentRoute.value.query?.opt === 'reload') {
      //   await router.replace({ query: { opt: 'no-reload' } })
      //   window.location.reload();
      // }

      userStr = LocalStorageServices.getUser();
      userObj.value = JSON.parse(userStr);
      bodyParameters.value.agent = [1, 6, 9].includes(userObj.value.role_id)
        ? "all"
        : "mine";

      getPolicyList(bodyParameters);
      MasterService.getCancellationReason();

      mainSocket
        .channel("policy-channel")
        .listen("CarPolicyCountSocketEvent", (response) => {
          // console.log("policy-channel", response);
          if (response.policy_status) {
            getPolicyList(bodyParameters, false);
          }
        });
        
    });

    const getPaginationUrl = (index) => {
      // let pagenationUrl = index.split("=");
      window.scrollTo({ top: 0, behavior: 'smooth' })
      bodyParameters.value.keyword = "";
      bodyParameters.value.index = currentPage.value;
      PolicyService.getPolicyList(bodyParameters.value);
    };
    const onPerPageChange = (event) => {
      window.scrollTo({ top: 0, behavior: 'smooth' })
      bodyParameters.value.per_page = event.target.value;
      PolicyService.getPolicyList(bodyParameters.value);
    };

    const getPolicyListPeriod = (listPeriod) => {
      if(listPeriod) {
        var startTS = new Date(listPeriod[0]);
        var endTS = new Date(listPeriod[1]);
        bodyParameters.value.start_date =
          startTS.getFullYear() +
          "-" +
          (startTS.getMonth() + 1) +
          "-" +
          startTS.getDate();
        bodyParameters.value.end_date =
          endTS.getFullYear() +
          "-" +
          (endTS.getMonth() + 1) +
          "-" +
          endTS.getDate();
      } else {
        bodyParameters.value.start_date = '';
        bodyParameters.value.end_date = '';
      }
      // bodyParameters.value.keyword = "";
      getPolicyList(bodyParameters);
    };

    let disableSaveButton = computed(() => {
      return store.getters.getDisableButtonStatus;
    });

    const search = ref<string>("");
    const searchPolicy = () => {
      // bodyParameters.value.keyword = search.value;
      getPolicyList(bodyParameters);
    };
    const resetBodyParam = () => {
      search.value = "";
      bodyParameters.value = {
        status: props.status,
        per_page: "50",
        keyword: "",
        start_date: "",
        end_date: "",
        agent: "all",
        policy_sales_agent: "",
        underwriter: "",
        payment_type: "",
        refund_status: "",
        cancellation_status: "",
        is_cancellation_selected: 1,
        index: 1,
        type: ""
      };
      value2.value = [];
      isDisabled.value = false;
      cancellationStatus.value = "6";
      getUnderwritterAgentList();
      getPolicyList(bodyParameters);
    };

    const getPolicyListFor = (status) => {
      bodyParameters.value.agent = status;
      getPolicyList(bodyParameters);
    };
    const getPolicyList = (bodyParameters, reload = true) => {
      currentPage.value = 1;
      bodyParameters.value.index = currentPage.value;
      bodyParameters.value.type = 'cancellation_request';
      bodyParameters.value.reload = reload
      Promise.all([
        PolicyService.getPolicyList(bodyParameters.value),
      ]).then((data) => {
        console.log(data);
      });
    };
   
    let policyListObj = computed(() => {
      return store.getters.getpolicyList;

      // if(data) {
      //   return data.forEach(element => {
      //     element['isLoading'] = false
      //     return element
      //   });
      // }
    });
    const getPaymentTypeFor = (paymentType) => {
      bodyParameters.value.payment_type = paymentType;
      getPolicyList(bodyParameters);
    };
    const onSalesAgentChange = (event) => {
      // bodyParameters.value.policy_sales_agent = event;
      getPolicyList(bodyParameters);
    };
    const onUnderwriterChange = (event) => {
      bodyParameters.value.underwriter = event;
      getPolicyList(bodyParameters);
    };
    const resetSalesAgent = (event) => {
      bodyParameters.value.policy_sales_agent = event == null ? "" : event;
      getPolicyList(bodyParameters.value);
    };
    const resetUnderWriter = (event) => {
      bodyParameters.value.underwriter = event == null ? "" : event;
      getPolicyList(bodyParameters.value);
    };
    let cancellationReasonList = computed(() => {
      return store.getters.getCancellationReasons;
    });

    const cancelModal = ref()
    const sendPolicyId = (policy, category = "standard", isOpenModal = false) => {
      // console.log("here");
      cancelModal.value.toFirst()
      childPolicyId.value = policy.id;
      PolicyService.resetPolicyDocumentValues([]);
      Promise.all([
        PolicyService.getPolicyDetails({
          policy_id: childPolicyId.value
        })
      ]).then((data) => {
        const docTypes = computed(() => {
          return store.getters.getMasterDocTypes;
        });

        policyDetails = computed(() => {
          return store.getters.getpolicyDetails;
        });
        if (policyDetails.value.status > 4 || category == "cancel") {
          categories.value = category == "cancel" ? "cancellation" : category;

          // categories.value = "cancellation";
          // console.log("childPolicyId", categories);
          getCancellationDocument(childPolicyId.value);
          if(isOpenModal) {
            PolicyService.setCancelModal(true);
            cancelModal.value.defaValue()
          }

        } else {
          categories.value = "standard";
        }
        if (docTypes.value.length == undefined) {
          Promise.all([
            MasterService.getDocumentTypes({
              categories: categories.value
            })
          ]).then((data) => {
            console.log("---", data);
          });
        }
        let title = category == "policy" ? "Policy Certificate" : "";
        getTempDocument(childPolicyId.value);
        getPolicyDocuments(childPolicyId.value, title);
        getCancellationDocument(childPolicyId.value);
      });

      PolicyService.resetPolicyError();
    };
    const getPolicyDocuments = (policy_id, title = "") => {
      PolicyService.getPolicyDocuments({
        policy_id: policy_id,
        title
      });
    };
    const getCancellationDocument = (policyId) => {
      Promise.all([
        PolicyService.getPolicyCancellationDocuments({
          policy_id: policyId,
          categories: categories.value
        })
      ]).then((data) => {
        cancellationDocs.value = computed(() => {
          return store.getters.getPolicyCancellationDocuments;
        });

        // console.log("---------------", cancellationDocs);
      });
    };
    const getTempDocument = (policyId) => {
      Promise.all([
        PolicyService.getTempPolicyDocuments({
          policy_id: policyId,
          categories: categories.value
        })
      ]).then((data) => {
        tempDocs.value = computed(() => {
          return store.getters.getTempPolicyDocs;
        });
      });
    };
    const resetPolicyData = () => {
      setTimeout(() => {
        getPolicyList(bodyParameters);
      }, 1000);
    };
    const showPolicyListDom = computed(() => {
      return store.getters.getPolicyListDom;
    });
    const payToList = computed(() => {
      return store.getters.getPayToList;
    });
    const insurancePaymentType = computed(() => {
      return store.getters.getInsurancePaymentList;
    });

    const onChangeRefundStatus = (event) => {
      refundPolicyBodyParam.value.refund_status = event.target.value;
    };

    const getForCancellationList = () => {
      
      bodyParameters.value.status = 6;
      bodyParameters.value.cancellation_status = "6";
      bodyParameters.value.is_cancellation_selected = 1;
      bodyParameters.value.refund_status = "";
      bodyParameters.value.type = 'refund_approve';
      if(router.currentRoute.value.query?.opt !== 'reload') {
        // window.location.reload();
      }
      getPolicyList(bodyParameters);
    };
    const getForRefundList = () => {
      bodyParameters.value.is_cancellation_selected = 0;
      bodyParameters.value.status = "";
      bodyParameters.value.cancellation_status = "";
      bodyParameters.value.refund_status = "1";
      bodyParameters.value.type = 'refund_approve';
      // refundStatus.value.data = [];
      getPolicyList(bodyParameters);
    };
    const onRefundFilterChange = (event) => {
      // if (event == "1") {
      //   event = "";
      // } else {
      bodyParameters.value.refund_status = event;
      // }
      // bodyParameters.value.status = null;
      isDisabled.value = ["2", "4"].includes(event) ? true : false;
      getPolicyList(bodyParameters);
    };
    const onCancelFilterChange = (event) => {
      cancellationStatus.value = event;
      if (event == "6") {
        event = "";
        bodyParameters.value.status = 6;
      } else {
        bodyParameters.value.status = null;
      }
      bodyParameters.value.is_cancellation_selected = 1;
      bodyParameters.value.cancellation_status = event;
      isDisabled.value = ["7", "9"].includes(event) ? true : false;

      getPolicyList(bodyParameters);
    };
    const isDisabled = ref<boolean>(false);

    const resetRefundFilter = (event) => {
      bodyParameters.value.is_cancellation_selected = 0;
      bodyParameters.value.refund_status = "1";
      // refundStatus.value.data = [];
      bodyParameters.value.status = null;
      getPolicyList(bodyParameters);
    };
    const resetCancelFilter = (event) => {
      bodyParameters.value.is_cancellation_selected = 1;
      bodyParameters.value.cancellation_status = "";
      // cancelStatus.value.data = [];
      bodyParameters.value.status = 6;
      getPolicyList(bodyParameters);
    };
    const confirmEvent = (policyObj) => {
      refundPolicyBodyParam.value.policy_status = 1;
      submitPolicyRefund(policyObj);
    };
    const cancelEvent = (policyObj) => {
      refundPolicyBodyParam.value.policy_status = 2;
      // submitPolicyRefund(policyObj);
    };
    const submitPolicyRefund = (policyObj) => {
      refundPolicyBodyParam.value.policy_id = policyObj.id;
      refundPolicyBodyParam.value.refund_payable_amount =
        policyObj.car_refund_request?.refund_payable_amount_agent == 0
          ? policyObj.car_refund_request?.refund_payable_amount_customer
          : policyObj.car_refund_request?.refund_payable_amount_agent;

      refundPolicyBodyParam.value.refund_status =
        policyObj.car_refund_request?.refund_status;
      refundPolicyBodyParam.value.pay_to = policyObj.car_refund_request.pay_to;

      policyObj.is_loading = true;

      // console.log({ jits: refundPolicyBodyParam.value });

      Promise.all([
        PolicyService.refundPolicyApproval(refundPolicyBodyParam.value)
      ])
        .then((data) => {
          policyObj.is_loading = false;
          // console.log(data, "-------data");
          // console.log(
          //   store.getters.getPolicyErrorDetails,
          //   "store.getters.getPolicyErrorDetails"
          // );
          if (!data[0].data.success && data[0].data.success != undefined) {
            let message = store.getters.getPolicyErrorDetails;
            // ElMessage.error(message[0]);
            notif.simple(`Policy Refund`, "warning", message[0], 0);
            // console.log(message);
          } else {
            notif.simple(`Policy Refund`, "success", data[0].message);

            // ElMessage({
            //   message: data[0].message,
            //   type: "success",
            // });
            getPolicyList(bodyParameters);
          }
        })
        .catch(() => {
          policyObj.is_loading = false;
        });
    };

    const submitPolicyCancellation = (policyObj) => {
      cancelPolicyBodyParam.value.policy_id = policyObj.id;
      cancelPolicyBodyParam.value.cancellation_payable_amount =
        policyObj.cancellation_payable_amount;
      cancelPolicyBodyParam.value.cancellation_debit_amount =
        policyObj.cancellation_debit_amount;
      cancelPolicyBodyParam.value.cancellation_credit_amount =
        policyObj.cancellation_credit_amount;
      cancelPolicyBodyParam.value.cancellation_status =
        policyObj.cancellation_status;
      cancelPolicyBodyParam.value.pay_to = policyObj.pay_to;
      cancelPolicyBodyParam.value.quote_ref_number =
        policyObj.car_lead_quote?.quote_ref_no;
      cancelPolicyBodyParam.value.cancellation_type =
        policyObj.cancellation_type;

      policyObj.loading = true;
      Promise.all([
        PolicyService.cancelPolicyApproval(cancelPolicyBodyParam.value)
      ])
        .then((data) => {
          policyObj.loading = false;
          // console.log(data, "-------data");
          // console.log(
          //   store.getters.getPolicyErrorDetails,
          //   "store.getters.getPolicyErrorDetails"
          // );
          if (!data[0].data.success && data[0].data.success != undefined) {
            let message = store.getters.getPolicyErrorDetails;
            // ElMessage.error(message[0]);
            // notif.simple("Policy Cancellation", "error", message[0]);
          } else {
            notif.simple("Policy Cancellation", "success", data[0].message);
            // ElMessage({
            //   message: data[0].message,
            //   type: "success",
            // });
            getPolicyList(bodyParameters);
          }
        })
        .catch(() => {
          policyObj.loading = false;
          // notif.simple("Policy Cancellation", "error", "Something went wrong!");
        });
    };

    const clearTemp = () => {
      PolicyService.resetTempDoc();
      // console.log(tempDocs.value);
      // tempDocs.value = "";
    };

    const exportPolicyAssigned = () => {
      Promise.all([
        PolicyService.exportPolicyAssigned(bodyParameters.value)
      ]).then((data) => {
        let exportApproval = computed(() => {
          return store.getters.getExportApproval;
        });
        exportUrl.value = exportApproval.value;
      });
    };

    const userPermissions = computed(() => {
      return store.getters.getUserPermissions;
    });

    const policyDocument = computed(() => {
      return store.getters.getPolicyDocs;
    });

    const paginatioObject = computed(() => {
      return store.getters.getPolicyPaginationObject;
    });

    const currentPage = ref(1);

    onBeforeRouteLeave(() => {
      ElNotification.closeAll();
    });

    const loading = computed(() => {
      return store.getters.getPaLoading;
    });

    // watchEffect(() => {
    //   if(getActiveTab.value && getActiveTab.value === 'refund'){
    //     getForRefundList();
    //   }
    // })

    const getCancelPolicyList = () => {
      getPolicyList(bodyParameters, false)
    };

    const getPolicyRefundList = () => {
      getPolicyList(bodyParameters)
    };
    
    const refundModal = computed(() => {
        return store.getters.getRefundModal;
    });

    const isViewCancellationModel = (isOpenView = false) => {
      isViewCancellation.value = isOpenView;
    };

    return {
      cancelModal,
      value2,
      cancellationStatus,
      cancelStatusOptions,
      refundStatusOptions,
      refundModal,
      getPolicyRefundList,
      getCancelPolicyList,
      getPolicyList,
      getActiveTab,
      loading,
      ElNotification,
      paginatioObject,
      currentPage,
      isDisabled,
      policyDocument,
      userPermissions,
      clearTemp,
      getPolicyListFor,
      search,
      searchPolicy,
      onPerPageChange,
      getPaginationUrl,
      bodyParameters,
      getPolicyListPeriod,
      resetBodyParam,
      policyListObj,
      agentList,
      underWriterList,
      getPaymentTypeFor,
      onUnderwriterChange,
      onSalesAgentChange,
      resetSalesAgent,
      resetUnderWriter,
      disableSaveButton,
      exportUrl,
      sendPolicyId,
      childPolicyId,
      resetPolicyData,
      showPolicyListDom,
      policyDetails,
      tempDocs,
      cancellationDocs,
      insurancePaymentType,
      payToList,
      submitPolicyCancellation,
      cancelPolicyBodyParam,
      getForCancellationList,
      getForRefundList,
      onChangeRefundStatus,
      confirmEvent,
      cancelEvent,
      onRefundFilterChange,
      resetRefundFilter,
      policyDocuments,
      onCancelFilterChange,
      resetCancelFilter,
      cancellationReasonList,
      exportPolicyAssigned,
      isViewCancellationModel,
      isViewCancellation,
    };
  }
});
