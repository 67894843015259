
import { defineComponent, onMounted } from "vue";
import { useStore } from "vuex";
import ListCancelPolicyApproval from "@/components/partials/policy/List-Cancel-Policy-Approval.vue";
import { MenuComponent } from "@/assets/ts/components";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import MasterService from "@/core/services/car/MasterService";
// import ListPolicy from "@/components/partials/policy/List-Policy.vue";
import { roles } from "@/store/stateless/store";

export default defineComponent({
  name: "policy-cancellation-approval",
  components: {
    ListCancelPolicyApproval,
// ListPolicy
    //   AIExplore
  },
  data() {
    return {
      status: 6,
    };
  },
  setup() {
    const store = useStore();
    onMounted(() => {
      if(!roles.policiesApprovedCancellationRefundRequest.includes(roles.userId())) window.location.href = '/#/404'
      
      
      Promise.all([
        MasterService.getPayToMasters({}),
        MasterService.getInsurancePaymentType({}),
      ]).then((data) => {
       console.log(data);
       
      });
     
      MenuComponent.reinitialization();

      setCurrentPageBreadcrumbs("Approve & View Cancellations", [
        "Policy",
      ]);
    });
    return {
      roles
    };
  },
});
