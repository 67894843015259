
import { defineComponent, ref, computed } from "vue";
import PolicyCancelRefundAction from "@/components/dropdown/car/PolicyCancelRefundAction.vue";
import PolicyService from "@/core/services/car/PolicyService";
import { notif } from "@/store/stateless/store";
import { useStore } from "vuex";
import { ElNotification } from "element-plus";
import { onBeforeRouteLeave } from "vue-router";
import { customFunction } from "@/store/stateless/store";

export default defineComponent({
  components: {
    PolicyCancelRefundAction,
  },

  props: {
    bodyParameters: {
      type: Object,
      required: true,
    },
    isDisabled: {
      type: Boolean,
      required: true,
    },
    isApproveViewCancellation: {
      type: Boolean,
      required: false,
    },
    cancellationStatuses: {
      type: String,
      required: false,
      default: '6'
    },
  },

  setup(props, context) {
    const currentPage = ref(1);
    const store = useStore();

    let cancelPolicyBodyParam = ref({
      policy_id: null,
      cancellation_payable_amount: null,
      cancellation_debit_amount: null,
      cancellation_credit_amount: null,
      cancellation_status: null,
      pay_to: "0",
      quote_ref_number: null,
      cancellation_type: null,
    });

    const getPolicyList = (bodyParameters) => {
      currentPage.value = 1;
      Promise.all([
        PolicyService.getPolicyList(bodyParameters),
        PolicyService.getPolicyListCount(bodyParameters),
      ]);
    };

    const submitPolicyCancellation = (policyObj) => {
      cancelPolicyBodyParam.value.policy_id = policyObj.id;
      cancelPolicyBodyParam.value.cancellation_payable_amount =
        policyObj.cancellation_payable_amount;
      cancelPolicyBodyParam.value.cancellation_debit_amount =
        policyObj.cancellation_debit_amount;
      cancelPolicyBodyParam.value.cancellation_credit_amount =
        policyObj.cancellation_credit_amount;
      cancelPolicyBodyParam.value.cancellation_status =
        policyObj.cancellation_status_id;
      cancelPolicyBodyParam.value.pay_to = policyObj.pay_to;
      cancelPolicyBodyParam.value.quote_ref_number =
        policyObj.car_lead_quote?.quote_ref_no;
      cancelPolicyBodyParam.value.cancellation_type =
        policyObj.cancellation_type;

      policyObj.loading = true;
      Promise.all([
        PolicyService.cancelPolicyApproval(cancelPolicyBodyParam.value),
      ])
        .then((data) => {
          policyObj.loading = false;
          // console.log(data, "-------data");
          // console.log(
          //   store.getters.getPolicyErrorDetails,
          //   "store.getters.getPolicyErrorDetails"
          // );
          if (!data[0].data.success && data[0].data.success != undefined) {
            // let message = store.getters.getPolicyErrorDetails;
            // notif.simple("Policy Cancellation", "error", message[0]);
          } else {
            notif.simple("Policy Cancellation", "success", data[0].message);

            getPolicyList(props.bodyParameters);
          }
        })
        .catch(() => {
          policyObj.loading = false;
        });
    };

    let cancellationReasonList = computed(() => {
      return store.getters.getCancellationReasons;
    });

    const payToList = computed(() => {
      return store.getters.getPayToList;
    });

    const sendPolicyId = (e, i, isOpen = false) => {
      // console.log('e',e)
      context.emit("sendPolicyId", e, i, isOpen);
    };
    const isViewCancellationDetailsCheck = (isOpenView = false) => {
      context.emit("isViewCancellationDetails", isOpenView);
    };

    let policyListObj = computed(() => {
      return store.getters.getpolicyList;
    });

    onBeforeRouteLeave(() => {
      ElNotification.closeAll();
    })

    return {
      ElNotification,
      cancellationReasonList,
      currentPage,
      submitPolicyCancellation,
      sendPolicyId,
      payToList,
      policyListObj,
      isViewCancellationDetailsCheck,
      customFunction
    };
  },
});
